<template>
  <section class="section">
    <!-- <div class="container">
      <h1 class="title is-4">DI Build Email Signature</h1>
      <hr />
    </div> -->
    <div class="container">
      <div class="columns">
        <div class="column">
          <div class="columns">
            <div class="column">
              <label class="label" for="firstName">First Name</label>
              <input
                required
                class="input"
                type="text"
                id="firstName"
                v-model="user.firstName"
              />
            </div>
            <div class="column">
              <label class="label" for="lastName">Last Name</label>
              <input
                required
                class="input"
                type="text"
                id="lastName"
                v-model="user.lastName"
              />
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <label class="label" for="jobTitle">Job Title</label>
              <input
                required
                class="input"
                type="text"
                id="jobTitle"
                v-model="user.jobTitle"
              />
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <label class="label">Office Number</label>
              <div class="columns">
                <div class="column">
                  <input
                    maxlength="3"
                    required
                    class="input"
                    v-model="user.officeNumber[0]"
                  />
                </div>
                <div class="column">
                  <input
                    maxlength="3"
                    required
                    class="input"
                    v-model="user.officeNumber[1]"
                  />
                </div>
                <div class="column">
                  <input
                    maxlength="4"
                    required
                    class="input"
                    v-model="user.officeNumber[2]"
                  />
                </div>
              </div>
            </div>
            <div class="column">
              <label class="label">Mobile Number</label>
              <div class="columns">
                <div class="column">
                  <input
                    maxlength="3"
                    required
                    class="input"
                    v-model="user.mobileNumber[0]"
                  />
                </div>
                <div class="column">
                  <input
                    maxlength="3"
                    required
                    class="input"
                    v-model="user.mobileNumber[1]"
                  />
                </div>
                <div class="column">
                  <input
                    maxlength="4"
                    required
                    class="input"
                    v-model="user.mobileNumber[2]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <table cellpadding="0" cellspacing="0" border="0" id="signature">
            <tr>
              <td>
                <div
                  style="
                    font-family: Verdana, Geneva, sans-serif;
                    font-size: 14px;
                    font-weight: bold;
                    color: #000;
                  "
                >
                  <strong style="color: rgb(153, 153, 153); font-weight: bold"
                    >{{ user.firstName }} {{ user.lastName }}</strong
                  >
                </div>
                <div
                  style="
                    font-family: Verdana, Geneva, sans-serif;
                    font-size: 12px;
                    color: rgb(153, 153, 153);
                  "
                >
                  {{ user.jobTitle }}
                </div>
                <div>
                  <img
                    style="width: 130px; padding-top: 10px"
                    src="https://di-signature.s3-us-west-2.amazonaws.com/di-build/di-build.png"
                    alt="DI Build Logo"
                  />
                </div>
                <div
                  style="
                    font-family: Verdana, Geneva, sans-serif;
                    font-size: 9px;
                    color: rgb(153, 153, 153);
                    line-height: 30px;
                  "
                >
                  <span v-if="officeNumberDisplay != '() -'">
                    <a
                      :href="`tel:${officeNumberLink}`"
                      style="
                        color: rgb(153, 153, 153);
                        text-decoration: none !important;
                      "
                    >
                      <span>{{ officeNumberDisplay }}</span>
                    </a>
                    <span>&nbsp;</span>
                    <strong style="color: rgb(153, 153, 153); font-weight: bold"
                      >office</strong
                    >
                  </span>
                  <span v-if="officeNumberDisplay != '() -'"
                    >&nbsp;&nbsp;|&nbsp;&nbsp;</span
                  >
                  <span v-if="mobileNumberDisplay != '() -'">
                    <a
                      :href="`tel:${mobileNumberLink}`"
                      style="
                        color: rgb(153, 153, 153);
                        text-decoration: none !important;
                      "
                    >
                      <span>{{ mobileNumberDisplay }}</span>
                    </a>
                    <span>&nbsp;</span>
                    <strong style="color: rgb(153, 153, 153); font-weight: bold"
                      >mobile</strong
                    >
                  </span>
                  <span v-if="mobileNumberDisplay != '() -'">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                  <a
                    style="
                      color: rgb(153, 153, 153);
                      text-decoration: underline !important;
                    "
                    href="http://dibuild.com"
                    >dibuild.com</a
                  >
                </div>
                <div
                  style="
                    font-family: Verdana, Geneva, sans-serif;
                    font-size: 9px;
                  "
                >
                  <strong style="color: rgb(153, 153, 153); font-weight: bold">
                    State Certified DBE/WBE & Federal Certified WBE
                  </strong>
                </div>
                <div style="padding-top: 20px">
                  <img
                    style="height: 40px; padding-right: 20px"
                    src="https://di-signature.s3-us-west-2.amazonaws.com/di-build/wbenc.png"
                    alt="WBENC"
                  />
                  <img
                    style="height: 40px"
                    src="https://di-signature.s3-us-west-2.amazonaws.com/di-build/kwob.png"
                    alt="KWOB"
                  />
                </div>
              </td>
            </tr>
          </table>
          <div class="buttons" style="margin-top: 3em">
            <a
              @click="isCopied = true"
              class="copy button is-primary"
              data-clipboard-target="#signature"
              >Copy!</a
            >
            <a href="/" class="button is-danger">Start Over</a>
          </div>
          <ol style="margin-left: 2em">
            <li>
              Hit the "Copy" button to copy your new signature to your
              clipboard.
            </li>
            <li>
              In Gmail, open your settings by clicking the gear icon near the
              top right.
            </li>
            <li>
              Paste your new signature into the field labeled "Signature".
              (CMD-V on Mac, CTRL-V on PC)
            </li>
            <li>Hit "Save Changes" at the bottom of the page.</li>
            <li>
              You're DONE! Having problems? Just email
              <a href="mailto:criebschlager@dimin.com">Chris</a>.
            </li>
          </ol>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Build",
  mounted() {
    const clipboard = new ClipboardJS(".copy");
  },
  watch: {
    "user.firstName": function (newVal, oldVal) {
      this.user.firstName = this.user.firstName.toUpperCase();
    },
    "user.lastName": function (newVal, oldVal) {
      this.user.lastName = this.user.lastName.toUpperCase();
    },
    "user.jobTitle": function (newVal, oldVal) {
      this.user.jobTitle = this.user.jobTitle.toUpperCase();
    },
  },
  computed: {
    officeNumberDisplay() {
      let n = this.user.officeNumber;
      return `(${n[0] || ""}) ${n[1] || ""}-${n[2] || ""}`;
    },
    officeNumberLink() {
      let n = this.user.officeNumber;
      return `1-${n[0] || ""}-${n[1] || ""}-${n[2] || ""}`;
    },
    mobileNumberDisplay() {
      let n = this.user.mobileNumber;
      return `(${n[0] || ""}) ${n[1] || ""}-${n[2] || ""}`;
    },
    mobileNumberLink() {
      let n = this.user.mobileNumber;
      return `1-${n[0] || ""}-${n[1] || ""}-${n[2] || ""}`;
    },
  },
  data() {
    return {
      isCopied: true,
      user: {
        firstName: "",
        lastName: "",
        jobTitle: "",
        officeNumber: ["913", "340", "0988"],
        mobileNumber: new Array(3),
      },
    };
  },
};
</script>

<style lang="scss" scoped>
</style>