<template>
  <div id="app">
    <div class="container" style="padding-top: 20px">
      <h1 class="title is-4">Dimensional Innovations Email Signature</h1>
      <hr />
      <ol>
        <li>Select which signature you would like to create from the dropdown below.</li>
        <li>Fill out the form with your details.</li>
        <li>Follow instructions on the right for adding your new signature into Gmail.</li>
      </ol>
      <div style="padding: 10px 20px;" class="dropdown" :class="rootClasses">
        <select v-model="selected" @click="toggle" class="dropdown-content">
          <option disabled selected value="Please Select">Please Select</option>
          <option v-for="(option, index) in options" :value="option.value" :key="index">
            {{ option.text }}
          </option>
        </select>
      </div>
    </div>
    <DI v-if="selected == 'Dimensional Innovations'" />
    <Build v-if="selected == 'DI Build'" />
    <Shield v-if="selected == 'Shield'" />
    <ISS v-if="selected == 'ISS'" />
  </div>
</template>

<script>
import DI from "@/components/DI";
import Build from "@/components/Build";
import Shield from "@/components/Shield";
import ISS from "@/components/ISS";

export default {
  name: "app",
  components: {
    DI,
    Build,
    Shield,
    ISS
  },
  data() {
    return {
      isActive: false,
      value: null,
      selected: "Please Select",
      options: [
        {
          value: "Dimensional Innovations",
          text: "Dimensional Innovations"
        },
        {
          value: "DI Build",
          text: "DI Build"
        },
        {
          value: "Shield",
          text: "Shield"
        },
        // {
        //   value: "ISS",
        //   text: "Infinity Sign Systems"
        // }
      ]
    };
  },
  methods: {
    toggle() {
      if (!this.isActive) {
        this.$nextTick(() => {
          const value = !this.isActive;
          this.isActive = value;
          setTimeout(() => (this.isActive = value));
        });
      } else {
        this.isActive = !this.isActive;
      }
    }
  },
  computed: {
    rootClasses() {
      return [
        {
          "is-active": this.isActive
        }
      ];
    }
  }
};
</script>
