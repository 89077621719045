<template>
  <section class="section">
    <div class="container">
      <div class="columns">
        <div class="column">
          <div class="columns">
            <div class="column">
              <label class="label" for="firstName">First Name</label>
              <input required class="input" type="text" id="firstName" v-model="user.firstName" />
            </div>
            <div class="column">
              <label class="label" for="lastName">Last Name</label>
              <input required class="input" type="text" id="lastName" v-model="user.lastName" />
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <label class="label" for="jobTitle">Job Title</label>
              <input required class="input" type="text" id="jobTitle" v-model="user.jobTitle" />
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <label class="label">Office Number</label>
              <div class="columns">
                <div class="column">
                  <input maxlength="3" required class="input" v-model="user.officeNumber[0]" />
                </div>
                <div class="column">
                  <input maxlength="3" required class="input" v-model="user.officeNumber[1]" />
                </div>
                <div class="column">
                  <input maxlength="4" required class="input" v-model="user.officeNumber[2]" />
                </div>
              </div>
            </div>
            <div class="column">
              <label class="label">Mobile Number</label>
              <div class="columns">
                <div class="column">
                  <input maxlength="3" required class="input" v-model="user.mobileNumber[0]" />
                </div>
                <div class="column">
                  <input maxlength="3" required class="input" v-model="user.mobileNumber[1]" />
                </div>
                <div class="column">
                  <input maxlength="4" required class="input" v-model="user.mobileNumber[2]" />
                </div>
              </div>
            </div>
          </div>
          <div>
            <input id="includeLink" type="checkbox" v-model="includeLink">
            <label for="includeLink">&nbsp;Include "Add Contact" Link</label>
          </div>
        </div>
        <div class="column">
          <table cellpadding="0" cellspacing="0" border="0" id="signature">
            <tr>
              <td>
                <img style="width:80px;height:auto;"
                  src="https://di-lobby.s3.us-west-2.amazonaws.com/email/di-sig-logo.png" />
              </td>
              <td>
                <div style="
                    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                    font-size: 13px;
                    font-weight: bold;
                    color: #000;
                  ">
                  <strong style="color: #000">{{ user.firstName }} {{ user.lastName }}</strong>
                </div>
                <div style="
                    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                    font-size: 11px;
                    color: rgb(153, 153, 153);
                  ">
                  {{ user.jobTitle }}
                </div>
                <div style="
                    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                    font-size: 11px;
                    color: rgb(153, 153, 153);
                  ">
                  <span v-if="mobileNumberDisplay != '() -'">
                    <a :href="`tel:${mobileNumberLink}`" style="
                        color: rgb(153, 153, 153);
                        text-decoration: none !important;
                      ">
                      <span>{{ mobileNumberDisplay }}</span>
                    </a>
                    <span>&nbsp;</span>
                    <strong style="color: rgb(153, 153, 153); font-weight: bold">mobile</strong>
                  </span>
                  <span v-if="mobileNumberDisplay != '() -'">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                  <span v-if="officeNumberDisplay != '() -'">
                    <a :href="`tel:${officeNumberLink}`" style="
                        color: rgb(153, 153, 153);
                        text-decoration: none !important;
                      ">
                      <span>{{ officeNumberDisplay }}</span>
                    </a>
                    <span>&nbsp;</span>
                    <strong style="color: rgb(153, 153, 153); font-weight: bold">office</strong>
                  </span>
                </div>
                <div style="
                    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                    font-size: 11px;
                  ">
                  <strong>
                    <a style="text-decoration: none; color: #c53435" href="http://dimin.com">
                      <span>Dimensional Innovations</span>
                    </a>
                  </strong>
                </div>
                <div style="
                    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                    font-size: 11px;
                    color: rgb(153, 153, 153);
                  ">
                  Named an
                  <strong style="color: #000">Inc. 5000</strong> Fastest-Growing Company for Seven Years
                </div>
                <div v-if="includeLink" style="
                    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                    font-size: 11px;
                    line-height: 24px;
                  ">
                  <a style="text-decoration: none; color: #c53435" :href="vcfLink">
                    <span>Add Contact</span>
                  </a>
                </div>
              </td>
            </tr>
          </table>
          <div class="buttons" style="margin-top: 3em">
            <a @click="isCopied = true" class="copy button is-primary" data-clipboard-target="#signature">Copy!</a>
            <a href="/" class="button is-danger">Start Over</a>
          </div>
          <ol style="margin-left: 2em">
            <li>
              Hit the "Copy" button to copy your new signature to your
              clipboard.
            </li>
            <li>
              In Gmail, open your settings by clicking the gear icon near the
              top right.
            </li>
            <li>
              Paste your new signature into the field labeled "Signature".
              (CMD-V on Mac, CTRL-V on PC)
            </li>
            <li>Hit "Save Changes" at the bottom of the page.</li>
            <li>
              You're DONE! Having problems? Just email
              <a href="mailto:criebschlager@dimin.com">Chris</a>.
            </li>
          </ol>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "DI",
  mounted() {
    const clipboard = new ClipboardJS(".copy");
  },
  watch: {
    "user.firstName": function (newVal, oldVal) {
      this.user.firstName = this.user.firstName.toUpperCase();
    },
    "user.lastName": function (newVal, oldVal) {
      this.user.lastName = this.user.lastName.toUpperCase();
    },
    "user.jobTitle": function (newVal, oldVal) {
      this.user.jobTitle = this.user.jobTitle.toUpperCase();
    },
  },
  methods: {
    titleCase(str) {
      str = str.toLowerCase().split(' ');
      for (let i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
      }
      return str.join(' ');
    }
  },
  computed: {
    vcfLink() {
      const lambdaUrl = `https://uzrj4w6mprn5pvtqzxtabtcfgu0ctadn.lambda-url.us-east-1.on.aws/`;
      const queryParams = {
        firstName: this.titleCase(this.user.firstName),
        lastName: this.titleCase(this.user.lastName),
        workPhone: this.user.officeNumber.join('-'),
        mobilePhone: this.user.mobileNumber.join('-'),
        title: this.titleCase(this.user.jobTitle),
      };
      let queryString = "?";
      Object.keys(queryParams).forEach(k => {
        queryString += `${k}=${queryParams[k]}&`
      })
      return `${lambdaUrl}${queryString}`;
    },
    officeNumberDisplay() {
      let n = this.user.officeNumber;
      return `(${n[0] || ""}) ${n[1] || ""}-${n[2] || ""}`;
    },
    officeNumberLink() {
      let n = this.user.officeNumber;
      return `1-${n[0] || ""}-${n[1] || ""}-${n[2] || ""}`;
    },
    mobileNumberDisplay() {
      let n = this.user.mobileNumber;
      return `(${n[0] || ""}) ${n[1] || ""}-${n[2] || ""}`;
    },
    mobileNumberLink() {
      let n = this.user.mobileNumber;
      return `1-${n[0] || ""}-${n[1] || ""}-${n[2] || ""}`;
    },
  },
  data() {
    return {
      isCopied: true,
      includeLink: false,
      user: {
        firstName: "",
        lastName: "",
        jobTitle: "",
        officeNumber: ["913", "384", "3488"],
        mobileNumber: new Array(3),
      },
    };
  },
};
</script>
