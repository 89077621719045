<template>
  <section class="section">
    <div class="container">
      <div class="columns">
        <div class="column">
          <div class="columns">
            <div class="column">
              <label class="label" for="firstName">First Name</label>
              <input required class="input" type="text" id="firstName" v-model="user.firstName" />
            </div>
            <div class="column">
              <label class="label" for="lastName">Last Name</label>
              <input required class="input" type="text" id="lastName" v-model="user.lastName" />
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <label class="label" for="jobTitle">Job Title</label>
              <input required class="input" type="text" id="jobTitle" v-model="user.jobTitle" />
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <label class="label">Office Number</label>
              <div class="columns">
                <div class="column">
                  <input maxlength="3" required class="input" v-model="user.officeNumber[0]" />
                </div>
                <div class="column">
                  <input maxlength="3" required class="input" v-model="user.officeNumber[1]" />
                </div>
                <div class="column">
                  <input maxlength="4" required class="input" v-model="user.officeNumber[2]" />
                </div>
              </div>
            </div>
            <div class="column">
              <label class="label">Mobile Number</label>
              <div class="columns">
                <div class="column">
                  <input maxlength="3" required class="input" v-model="user.mobileNumber[0]" />
                </div>
                <div class="column">
                  <input maxlength="3" required class="input" v-model="user.mobileNumber[1]" />
                </div>
                <div class="column">
                  <input maxlength="4" required class="input" v-model="user.mobileNumber[2]" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <table cellpadding="0" cellspacing="0" border="0" id="signature">
            <tr>
              <td>
                <div style="
                    font-family: Roboto, sans-serif;
                    font-size: 15px;
                    font-weight: bold;
                    color: rgb(44,57,68);
                  ">
                  <strong style="color: #000">{{ user.firstName }} {{ user.lastName }}</strong>
                </div>
                <div style="
                    font-family: Roboto, sans-serif;
                    font-size: 13px;
                    color: #666;
                    font-weight: bold;
                    font-style: italic;
                  ">
                  {{ user.jobTitle }}
                </div>
                <div style="
                    font-family: Roboto, sans-serif;
                    font-size: 13px;
                    color: rgb(51,153,204);
                    font-weight: bold;
                  ">
                  <a href="https://www.shieldcasework.com/">HEALTHCARE</a><span style="color:#000">&nbsp;|&nbsp;</span><a href="https://www.shieldlockers.com/">SPORT</a>
                </div>
                <div v-if="mobileNumberDisplay != '..'"
                  style="
                    font-family: Roboto, sans-serif;
                    font-size: 13px;
                    color: #000;
                  ">
                    <strong style="color: #000; font-weight: bold">C:&nbsp;</strong>
                    <a :href="`tel:${mobileNumberLink}`" style="
                        color: #000;
                        text-decoration: none !important;
                      ">
                      <span>{{ mobileNumberDisplay }}</span>
                    </a>
                </div>
                <div
                  v-if="officeNumberDisplay != '..'"
                  style="
                    font-family: Roboto, sans-serif;
                    font-size: 13px;
                  ">
                  <strong style="color: #000; font-weight: bold">O:&nbsp;</strong>
                  <a :href="`tel:${officeNumberLink}`" style="
                      color: #000;
                      text-decoration: none !important;
                    ">
                    <span>{{ officeNumberDisplay }}</span>
                  </a>
                </div>
                <br />
                <img style="width:100px;height:auto;"
                  src="https://di-lobby.s3.us-west-2.amazonaws.com/email/shield.png" />
                <div style="
                    font-family: Roboto, sans-serif;
                    font-size: 10px;
                    color: #222;
                    font-style: italic;
                  ">
                  Proudly designed and made in USA
                </div>

              </td>
            </tr>
          </table>
          <div class="buttons" style="margin-top: 3em">
            <a @click="isCopied = true" class="copy button is-primary" data-clipboard-target="#signature">Copy!</a>
            <a href="/" class="button is-danger">Start Over</a>
          </div>
          <ol style="margin-left: 2em">
            <li>
              Hit the "Copy" button to copy your new signature to your
              clipboard.
            </li>
            <li>
              In Gmail, open your settings by clicking the gear icon near the
              top right.
            </li>
            <li>
              Paste your new signature into the field labeled "Signature".
              (CMD-V on Mac, CTRL-V on PC)
            </li>
            <li>Hit "Save Changes" at the bottom of the page.</li>
            <li>
              You're DONE! Having problems? Just email
              <a href="mailto:criebschlager@dimin.com">Chris</a>.
            </li>
          </ol>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Shield",
  mounted() {
    const clipboard = new ClipboardJS(".copy");
  },
  watch: {
    "user.firstName": function (newVal, oldVal) {
      this.user.firstName = this.user.firstName.toUpperCase();
    },
    "user.lastName": function (newVal, oldVal) {
      this.user.lastName = this.user.lastName.toUpperCase();
    },
  },
  methods: {
    titleCase(str) {
      str = str.toLowerCase().split(' ');
      for (let i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
      }
      return str.join(' ');
    }
  },
  computed: {
    officeNumberDisplay() {
      let n = this.user.officeNumber;
      return `${n[0] || ""}.${n[1] || ""}.${n[2] || ""}`;
    },
    officeNumberLink() {
      let n = this.user.officeNumber;
      return `1-${n[0] || ""}-${n[1] || ""}-${n[2] || ""}`;
    },
    mobileNumberDisplay() {
      let n = this.user.mobileNumber;
      return `${n[0] || ""}.${n[1] || ""}.${n[2] || ""}`;
    },
    mobileNumberLink() {
      let n = this.user.mobileNumber;
      return `1-${n[0] || ""}-${n[1] || ""}-${n[2] || ""}`;
    },
  },
  data() {
    return {
      isCopied: true,
      includeLink: false,
      user: {
        firstName: "",
        lastName: "",
        jobTitle: "",
        officeNumber: ["816", "875", "3317"],
        mobileNumber: new Array(3),
      },
    };
  },
};
</script>
