<template>
  <section class="section">
    <div class="container">
      <div class="columns">
        <div class="column">
          <div class="columns">
            <div class="column">
              <label class="label" for="firstName">First Name</label>
              <input required class="input" type="text" id="firstName" v-model="user.firstName" />
            </div>
            <div class="column">
              <label class="label" for="lastName">Last Name</label>
              <input required class="input" type="text" id="lastName" v-model="user.lastName" />
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <label class="label" for="jobTitle">Job Title</label>
              <input required class="input" type="text" id="jobTitle" v-model="user.jobTitle" />
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <label class="label" for="email">Email Address</label>
              <input required class="input" type="text" id="email" v-model="user.email" />
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <label class="label">Office Number</label>
              <div class="columns">
                <div class="column">
                  <input maxlength="3" required class="input" v-model="user.officeNumber[0]" />
                </div>
                <div class="column">
                  <input maxlength="3" required class="input" v-model="user.officeNumber[1]" />
                </div>
                <div class="column">
                  <input maxlength="4" required class="input" v-model="user.officeNumber[2]" />
                </div>
              </div>
            </div>
            <div class="column">
              <label class="label">Mobile Number</label>
              <div class="columns">
                <div class="column">
                  <input maxlength="3" required class="input" v-model="user.mobileNumber[0]" />
                </div>
                <div class="column">
                  <input maxlength="3" required class="input" v-model="user.mobileNumber[1]" />
                </div>
                <div class="column">
                  <input maxlength="4" required class="input" v-model="user.mobileNumber[2]" />
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <label class="label">Fax Number</label>
              <div class="columns">
                <div class="column">
                  <input maxlength="3" required class="input" v-model="user.faxNumber[0]" />
                </div>
                <div class="column">
                  <input maxlength="3" required class="input" v-model="user.faxNumber[1]" />
                </div>
                <div class="column">
                  <input maxlength="4" required class="input" v-model="user.faxNumber[2]" />
                </div>
              </div>
            </div>
            <div class="column"></div>
          </div>
        </div>
        <div class="column">
          <table cellpadding="0" cellspacing="0" border="0" id="signature">
            <tr>
              <td>
                <img style="width:192px;height:auto;"
                  src="https://di-lobby.s3.us-west-2.amazonaws.com/email/iss.png" />
                <br /><br />
                <div style="
                    font-family: Helvetica, Arial, sans-serif;
                    font-size: 13px;
                    color: #000;
                  ">
                  <p>4900 Lister Ave</p>
                  <p>PO Box 300228</p>
                  <p>Kansas City, MO 64130</p>
                </div>
                <br />
                <div
                  style="
                    font-family: Helvetica, Arial, sans-serif;
                    font-size: 13px;
                    color: #000;
                  ">
                  PH:&nbsp;
                  <a :href="`tel:${officeNumberLink}`" style="
                      color: #000;
                      text-decoration: none !important;
                    ">
                    <span>{{ officeNumberDisplay }}</span>
                  </a>
                </div>
                <div v-if="mobileNumberDisplay != '--'"
                  style="
                    font-family: Helvetica, Arial, sans-serif;
                    font-size: 13px;
                    color: #000;
                  ">
                    Mobile:&nbsp;
                    <a :href="`tel:${mobileNumberLink}`" style="
                        color: #000;
                        text-decoration: none !important;
                      ">
                      <span>{{ mobileNumberDisplay }}</span>
                    </a>
                </div>
                <div v-if="faxNumberDisplay != '--'"
                  style="
                    font-family: Helvetica, Arial, sans-serif;
                    font-size: 13px;
                    color: #000;
                  ">
                  Fax:&nbsp;
                    <span>{{ faxNumberDisplay }}</span>
                </div>
                <div style="
                    font-family: Helvetica, Arial, sans-serif;
                    font-size: 13px;
                    color: #000;
                  ">
                  {{ user.email }}
                </div>
                <div style="
                    font-family: Helvetica, Arial, sans-serif;
                    font-size: 13px;
                  ">
                  <a href="https://infinitysignsystems.com/">www.infinitysign.com</a>
                </div>
              </td>
            </tr>
          </table>
          <div class="buttons" style="margin-top: 3em">
            <a @click="isCopied = true" class="copy button is-primary" data-clipboard-target="#signature">Copy!</a>
            <a href="/" class="button is-danger">Start Over</a>
          </div>
          <ol style="margin-left: 2em">
            <li>
              Hit the "Copy" button to copy your new signature to your
              clipboard.
            </li>
            <li>
              In Gmail, open your settings by clicking the gear icon near the
              top right.
            </li>
            <li>
              Paste your new signature into the field labeled "Signature".
              (CMD-V on Mac, CTRL-V on PC)
            </li>
            <li>Hit "Save Changes" at the bottom of the page.</li>
            <li>
              You're DONE! Having problems? Just email
              <a href="mailto:criebschlager@dimin.com">Chris</a>.
            </li>
          </ol>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ISS",
  mounted() {
    const clipboard = new ClipboardJS(".copy");
  },
  computed: {
    officeNumberDisplay() {
      let n = this.user.officeNumber;
      return `${n[0] || ""}-${n[1] || ""}-${n[2] || ""}`;
    },
    officeNumberLink() {
      let n = this.user.officeNumber;
      return `1-${n[0] || ""}-${n[1] || ""}-${n[2] || ""}`;
    },
    mobileNumberDisplay() {
      let n = this.user.mobileNumber;
      return `${n[0] || ""}-${n[1] || ""}-${n[2] || ""}`;
    },
    mobileNumberLink() {
      let n = this.user.mobileNumber;
      return `1-${n[0] || ""}-${n[1] || ""}-${n[2] || ""}`;
    },
    faxNumberDisplay() {
      let n = this.user.faxNumber;
      return `${n[0] || ""}-${n[1] || ""}-${n[2] || ""}`;
    },
  },
  data() {
    return {
      isCopied: true,
      includeLink: false,
      user: {
        firstName: "",
        lastName: "",
        jobTitle: "",
        email: "",
        officeNumber: ["819", "252", "3337"],
        mobileNumber: new Array(3),
        faxNumber: new Array(3)
      },
    };
  },
};
</script>
